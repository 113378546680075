import { globalHistory } from '@reach/router';

export const onClientEntry = () => {
  if (typeof window !== 'undefined') {
    if (!window.gtag) {
      const script = document.createElement('script');
      script.async = true;
      script.src = 'https://www.googletagmanager.com/gtag/js?id=G-WEXPRQ62BQ';
      document.head.appendChild(script);

      window.dataLayer = window.dataLayer || [];
      function gtag() {
        window.dataLayer.push(arguments);
      }
      window.gtag = gtag;

      gtag('js', new Date());

      gtag('consent', 'default', {
        ad_storage: 'denied',
        analytics_storage: 'denied',
        ad_user_data: 'denied',
        ad_personalization: 'denied',
      });

      gtag('config', 'G-WEXPRQ62BQ', {
        anonymize_ip: true,
        allow_google_signals: false,
      });

      const storedConsent = localStorage.getItem('accepted');
      if (storedConsent !== null) {
        const isAccepted = /true/i.test(storedConsent);
        gtag('consent', 'update', {
          ad_storage: isAccepted ? 'granted' : 'denied',
          analytics_storage: isAccepted ? 'granted' : 'denied',
          ad_user_data: isAccepted ? 'granted' : 'denied',
          ad_personalization: isAccepted ? 'granted' : 'denied',
        });
      }
    }
  }
};

export const onRouteUpdate = ({ location }) => {
  if (typeof window !== 'undefined' && window.gtag) {
    window.gtag('event', 'page_view', {
      page_path: location.pathname,
    });
  }

  globalHistory.listen((args) => {
    args.location.action = args.action;
  });
};
